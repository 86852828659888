<template>
  <link rel="shortcut icon" type="image/png" href="/images/favicon.png"/>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="glossy bg-purple">
      <q-toolbar>
        <q-btn flat dense round @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Options" icon="menu"/>
        <q-toolbar-title>
          Dungeon Defenders Code Redeem
        </q-toolbar-title>
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" bordered class="drawer" :width="265">
      <q-list>
        <q-item-label header>Display Options</q-item-label>
        <q-item>
          <q-item-section>
            <q-item-label>Light Mode</q-item-label>
            <div class="q-gutter-sm">
              <q-toggle size="s" color="purple" v-model="optionLightMode" inline @update:model-value="toggleLightMode()"></q-toggle>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <q-page class="flex flex-center">
        <div id="q-app" style="min-height: 94vh;">
          <div class="q-pa-md q-gutter-sm">
            <q-banner rounded class="bg-grey">
              <div><q-btn color="red" label="IMPORTANT"></q-btn> <u>This site is for redeeming PlayFab/Backer Reward codes only!</u> It can not redeem platform specific codes to unlock games or DLC as those must be redeem through their respective websites or on-platform stores.</div>
            </q-banner>
          </div>
          <div class="q-pa-md">
            <q-form @submit="submitForm" @reset="resetForm" class="q-gutter-md">
              <q-input filled v-model="playfabid" label="Your PlayFabId *" hint="Located on the Options screen!" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']"></q-input>
              <q-input filled v-model="redeemcode" label="Redeem Code *" hint="Example: abc-abcd-abc" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']"></q-input>
              <q-select filled readonly v-model="game" label="Game" map-options :options="gameSelection" style="width: 275px"></q-select>
              <q-toggle v-model="nobot" color="purple" label="I am not a bot."></q-toggle>
              <div>
                <q-btn label="Submit" type="submit" color="purple"></q-btn>
                <q-btn label="Reset" type="reset" color="negative" flat class="q-ml-sm"></q-btn>
              </div>
            </q-form>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<style>
.body--light .drawer {
  background: #f5f5f5 !important;
}

#terms {
  text-decoration: none;
  color: var(--q-primary);
}

#terms:hover {
  color: var(--q-accent);
}

.q-field--filled.q-field--readonly .q-field__control:before {
  border-bottom-style: none !important;
}

body {
  cursor: url('../public/images/cursor.png'), auto;
}
</style>


<script>
import { ref } from 'vue'
import { pfRequest } from './js/query';
import { Dark, Notify } from 'quasar';

export default {
  name: 'App',
  setup () {
    const searchParams = new URLSearchParams(window.location.search);
    const optionLightMode = ref(localStorage.getItem('optionLightMode')==='true');
    const playfabid = ref(searchParams.get('playfabid'));
    const redeemcode = ref(null);
    const nobot = ref(false);
    const live = ref(searchParams.get('live') || true);
    const game = ref(searchParams.get('game') || "dda");

    const toggleLightMode = () => {
      Dark.set(!optionLightMode.value);
      window.localStorage.setItem('optionLightMode', optionLightMode.value);
    };

    const resetForm = () => {
      redeemcode.value = null;
      nobot.value = false;
    };

    const submitForm = async () => {
      if (nobot.value == true && (game.value == "dda" || game.value == "ddgr" )) {
        await pfRequest(game.value.trim(), redeemcode.value.trim(), playfabid.value.trim(), live.value).then((response) => {
          let msg = "Code Redeemed Successfully! If your game client is already open you may need to restart it to access the rewards.";
          let notifyType = 'positive';
          if (response.status != 200) {
            msg = "Error! Please make sure your PlayFabId and Code were typed in correctly and try again. Error Code: " + response.status;
            notifyType = 'negative';
            nobot.value = false;
          } else if (response.status == 200) {
            redeemcode.value = null;
          }

          Notify.create({
            type: notifyType,
            progress: true,
            message: msg,
            timeout: 10000,
          })
        });
      }
    };

    return {
      gameSelection: [
        { label: 'Dungeon Defenders: Awakened', value: 'dda' }, 
        { label: 'Dungeon Defenders: Going Rogue', value: 'ddgr' },
      ],
      leftDrawerOpen: ref(false),
      optionLightMode,
      playfabid,
      redeemcode,
      nobot,
      game,
      toggleLightMode,
      resetForm,
      submitForm
    }
  },
  created() {
    document.title = `Dungeon Defenders Code Redeem`;
    this.toggleLightMode();
  },
}
</script>
