import axios from "axios";

const pfRequest = (game, redeemcode, playfabid, live = true) => {
  const headers = {
    game: game,
    redeemcode: redeemcode,
    playfabid: playfabid,
    "X-Api-Key": "3pn6flt7Un5ObNkIdBMFK8DT9GBN4jMg1qTyvgUr",
  }

  let livePath = "live";
  if (live != true)
    livePath = "internal";

  return axios.post('https://05jbkboqn5.execute-api.us-east-1.amazonaws.com/' + livePath, {}, { headers, timeout: 10000 })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return ({status: error.response.status, statusText: "Error"});
    });
}

export {
  pfRequest
}